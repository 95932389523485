import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo/SEO';
import Layout from '../layouts/index';
import Helmet from 'react-helmet';

const Legal = ({ data }) => {
  const { title,path } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  return (
    <Layout bodyClass="page-legal">
      <SEO title={title} url={path}/>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div className="container pt-4 pt-md-10">
        <div className="row justify-content-start">

          <div className="service service-single">
            <h1 className="title">{title}</h1>
            <div className="legal-content" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>

      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
      }
      html
    }
  }
`;

export default Legal;
